import { __decorate } from "tslib";
import { Vue, Component, Watch, Inject } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import DataSetObjectsModal from '@/components/data-sets/DataSetObjectsModal.vue';
import DeclarativeFilter from '@/components/shared/DeclarativeFilter.vue';
import DataSetsApi from '@/services/api/data-sets.api';
import { DeclarativeFilterItem } from '@/classes/declarative-filter-item';
const TEXT_FIELD_MAX_LENGTH = 255;
let DatasetsManagePage = class DatasetsManagePage extends Vue {
    constructor() {
        super(...arguments);
        this.DESCRIPTION_MAX_LENGTH = TEXT_FIELD_MAX_LENGTH;
        this.dataSetId = this.$route.params.dataSetId;
        this.loading = false;
        this.objectDescribe = null;
        this.selectedFields = [];
        this.showModal = false;
        this.selectedFilterMode = 0;
        // Data set form
        this.name = '';
        this.description = '';
        this.fields = '';
        this.objectApiName = '';
        this.soqlFilter = '';
        this.declarativeFilter = '';
        this.declarativeFilterCustomLogic = '';
        // Data sed form END
        this.soqlFilterError = '';
        this.recordsCount = -1;
        this.declarativeFilterData = {
            filters: [],
        };
    }
    resetRecordsCount() {
        this.recordsCount = -1;
    }
    get mode() {
        return this.dataSetId === 'new' ? 'create' : 'edit';
    }
    get showLoading() {
        return this.loading;
    }
    get disabledFields() {
        if (!this.objectDescribe) {
            return [];
        }
        return this.objectDescribe.fields.filter(field => field.isRequired);
    }
    get fieldsWithTypes() {
        return this.objectDescribe
            ? this.objectDescribe.fields.reduce((acc, next) => ({ ...acc, [next.name]: next.type }), {})
            : {};
    }
    mounted() {
        if (this.mode === 'create') {
            this.showModal = true;
        }
        else {
            this.fetchDataSet();
        }
    }
    async countRecords() {
        if (!this.objectDescribe) {
            return;
        }
        try {
            this.loading = true;
            this.validateDeclarativeFilter();
            const { total } = await DataSetsApi.countRecords({
                objectApiName: this.objectApiName,
                declarativeFilterCustomLogic: this.declarativeFilterCustomLogic,
                declarativeFilter: this.declarativeFilter,
                soqlFilter: this.soqlFilter,
                filterMode: this.filterMode,
                fieldsDescribe: this.objectDescribe.fields,
                organizationId: +this.organizationId,
            });
            this.recordsCount = total;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    async submit() {
        try {
            this.$v.$touch();
            if (!this.fields) {
                this.$toast.error('Pick at least one field from list!');
            }
            if (this.$v.$error || !this.objectDescribe) {
                return;
            }
            this.validateDeclarativeFilter();
            this.loading = true;
            const { name, fields, objectApiName, description, filterMode, soqlFilter, declarativeFilter, declarativeFilterCustomLogic, } = this;
            const body = {
                name,
                fields,
                objectApiName,
                description,
                filterMode,
                soqlFilter: this.filterMode === 'soql' ? soqlFilter : '',
                declarativeFilter: this.filterMode === 'soql' ? '' : declarativeFilter,
                declarativeFilterCustomLogic: this.filterMode === 'soql' ? '' : declarativeFilterCustomLogic,
                fieldsDescribe: this.objectDescribe.fields,
                organizationId: +this.organizationId,
            };
            if (this.mode === 'create') {
                await DataSetsApi.create(body);
                this.$toast.success('Data Set successfully created');
            }
            else {
                await DataSetsApi.update(+this.dataSetId, body);
                this.$toast.success('Data Set successfully updated');
            }
            await this.$router.push({
                name: 'data-sets.index',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    setObjectName(name) {
        this.objectApiName = name;
    }
    async validateSOQLFilter() {
        try {
            this.soqlFilterError = '';
            this.loading = true;
            await DataSetsApi.validateSoqlFilter({
                organizationId: +this.organizationId,
                soqlFilter: this.soqlFilter,
                objectApiName: this.objectApiName,
            });
            this.$toast.success('SOQL Filter has valid syntax');
        }
        catch (error) {
            this.soqlFilterError = error.message;
            this.$toast.error('SOQL Filter has invalid syntax');
        }
        finally {
            this.loading = false;
        }
    }
    onSelectedFieldsChanged() {
        this.fields = this.selectedFields.map(field => field.name).join(',');
    }
    onFilterModeChanged() {
        this.filterMode = this.selectedFilterMode ? 'soql' : 'declarative';
        this.soqlFilterError = '';
        this.recordsCount = -1;
    }
    onObjectApiNameChanged() {
        this.fetchObjectDescribe();
    }
    onDeclarativeFilterUpdate() {
        this.declarativeFilter = JSON.stringify(this.declarativeFilterData.filters.map(({ field, option, value }) => ({
            field,
            option,
            value,
        })));
        this.declarativeFilterCustomLogic = this.declarativeFilterData.customLogic || '';
    }
    validateDeclarativeFilter() {
        for (const filter of this.declarativeFilterData.filters) {
            if (filter.field === '' || filter.option === null) {
                throw new Error('Declarative filter has empty fields or options.');
            }
        }
    }
    async fetchDataSet() {
        try {
            this.loading = true;
            const dataSet = await DataSetsApi.findById(+this.dataSetId);
            this.name = dataSet.name;
            this.description = dataSet.description || '';
            this.soqlFilter = unescape(dataSet.soqlFilter || '');
            this.objectApiName = dataSet.objectApiName;
            this.fields = dataSet.fields;
            this.filterMode = dataSet.filterMode;
            this.declarativeFilter = dataSet.declarativeFilter || '[]';
            this.declarativeFilterCustomLogic = dataSet.declarativeFilterCustomLogic || '';
            this.selectedFilterMode = this.filterMode === 'soql' ? 1 : 0;
        }
        catch (error) {
            this.$toast.error(error.message);
            this.$router.push({
                name: 'data-sets.index',
            });
        }
        finally {
            this.loading = false;
        }
    }
    async fetchObjectDescribe() {
        try {
            this.loading = true;
            this.objectDescribe = await DataSetsApi.objectDescribe(+this.organizationId, this.objectApiName);
            const dataSetSelectedFields = this.fields.split(',').map(v => v.trim());
            this.selectedFields = this.objectDescribe.fields.filter(field => field.isRequired || dataSetSelectedFields.includes(field.name));
            this.declarativeFilterData = {
                filters: JSON.parse(this.declarativeFilter || '[]').map(filter => new DeclarativeFilterItem(filter.field, filter.option, filter.value, this.fieldsWithTypes)),
                customLogic: this.declarativeFilterCustomLogic || undefined,
            };
        }
        catch (error) {
            this.$toast.error(error.message);
            this.$router.push({
                name: 'data-sets.index',
            });
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], DatasetsManagePage.prototype, "organizationId", void 0);
__decorate([
    Validate({ required, maxLength: maxLength(TEXT_FIELD_MAX_LENGTH) })
], DatasetsManagePage.prototype, "name", void 0);
__decorate([
    Validate({ maxLength: maxLength(TEXT_FIELD_MAX_LENGTH) })
], DatasetsManagePage.prototype, "description", void 0);
__decorate([
    Validate({ required })
], DatasetsManagePage.prototype, "fields", void 0);
__decorate([
    Validate({ required })
], DatasetsManagePage.prototype, "objectApiName", void 0);
__decorate([
    Watch('declarativeFilter'),
    Watch('declarativeFilterCustomLogic'),
    Watch('soqlFilter')
], DatasetsManagePage.prototype, "resetRecordsCount", null);
__decorate([
    Watch('selectedFields', { deep: true, immediate: true })
], DatasetsManagePage.prototype, "onSelectedFieldsChanged", null);
__decorate([
    Watch('selectedFilterMode', { immediate: true })
], DatasetsManagePage.prototype, "onFilterModeChanged", null);
__decorate([
    Watch('objectApiName')
], DatasetsManagePage.prototype, "onObjectApiNameChanged", null);
__decorate([
    Watch('declarativeFilterData', { deep: true })
], DatasetsManagePage.prototype, "onDeclarativeFilterUpdate", null);
DatasetsManagePage = __decorate([
    Component({
        components: {
            DataSetObjectsModal,
            DeclarativeFilter,
        },
    })
], DatasetsManagePage);
export default DatasetsManagePage;
