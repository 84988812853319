var DataSetObjectsModal_1;
import { __decorate } from "tslib";
import { Vue, Component, Emit, Inject, Watch } from 'vue-property-decorator';
import DataSetsApi from '@/services/api/data-sets.api';
import { DEFAULT_PAGINATION_LIMIT } from '@/components/FPagination.vue';
let DataSetObjectsModal = DataSetObjectsModal_1 = class DataSetObjectsModal extends Vue {
    constructor() {
        super(...arguments);
        this.organizationObjects = [];
        this.modalId = DataSetObjectsModal_1.name;
        this.tableFields = [
            { key: 'name', label: 'Name', thStyle: { width: '50%' } },
            { key: 'label', label: 'Label', thStyle: { width: '50%' } },
        ];
        this.pagination = {
            limit: DEFAULT_PAGINATION_LIMIT,
            page: 1,
            total: 0,
        };
        this.loading = false;
        this.searchText = '';
    }
    get totalItems() {
        const searchRegexp = new RegExp(this.searchText, 'i');
        return this.organizationObjects.filter(({ name }) => searchRegexp.test(name)).length;
    }
    get items() {
        const searchRegexp = new RegExp(this.searchText, 'i');
        const { page, limit } = this.pagination;
        const offset = (page - 1) * limit;
        return this.organizationObjects.filter(({ name }) => searchRegexp.test(name)).slice(offset, offset + limit);
    }
    mounted() {
        this.$bvModal.show(this.modalId);
        this.fetchData();
    }
    onObjectSelected(object) {
        this.hideModal();
        return object.name;
    }
    handleModalClose() {
        this.$nextTick(() => this.closeModal());
    }
    cancel() {
        this.$router.push({
            name: 'data-sets.index',
        });
    }
    closeModal() {
        return this.modalId;
    }
    onItemsChanged() {
        this.pagination.page = 1;
        this.pagination.total = this.totalItems;
    }
    hideModal() {
        this.$bvModal.hide(this.modalId);
    }
    async fetchData() {
        try {
            this.loading = true;
            this.organizationObjects = await DataSetsApi.globalDescribe(+this.organizationId);
            this.pagination.total = this.organizationObjects.length;
        }
        catch (error) {
            this.$toast.error(error.message);
            this.$router.push({
                name: 'data-sets.index',
            });
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Inject()
], DataSetObjectsModal.prototype, "organizationId", void 0);
__decorate([
    Emit()
], DataSetObjectsModal.prototype, "onObjectSelected", null);
__decorate([
    Emit()
], DataSetObjectsModal.prototype, "closeModal", null);
__decorate([
    Watch('searchText', { deep: true })
], DataSetObjectsModal.prototype, "onItemsChanged", null);
DataSetObjectsModal = DataSetObjectsModal_1 = __decorate([
    Component({
        components: {},
    })
], DataSetObjectsModal);
export default DataSetObjectsModal;
